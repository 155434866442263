import React, { useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import './CustomerVehicles.scss';
import { clearCustomerVehicleData, loadCustomerVehicleReferencesData, sortCustomerVehicleDataList, resetToast, setToastShownAction } from '../../../redux/store/CustomerVehicleInfo';
import CustomerVehiclesDataTable from '../../../components/CustomerVehicles/CustomerVehiclesDataTables';
import _ from 'lodash';
import { UserContext } from '../../../appContext';
import { LocalizationHelper, Toast, useToast } from '@cd/sdds-common-components-react';

const CustomerVehicles = () => {
  const dispatch = useAppDispatch();
  const { customerVehicleInfoList, numberOfVehiclesCount, searchCustomerReference, isVehiclesInfoListLoading, hasError, toastShown } = useAppSelector<CustomerVehiclesStateType>(state => state.customerVehiclesInfoStore);
  const { selectedCustomer } = useAppSelector<SearchState>(state => state.universalSearch)
  const [searchResults, setSearchResults] = useState<Array<simplifiedCustomerVehicleInfo>>(customerVehicleInfoList);
  const { settings } = useContext(UserContext);
  const [unitType, setUnitType] = useState('Metric');
  const { t } = LocalizationHelper.useLocalize();

  const { toastQueue, addToast } = useToast()
  const [isRefetching, setIsRefetching] = useState(false);
  
  const refetchData = async () => {
    if (selectedCustomer?.externalCustomerReference) {
      setIsRefetching(true);
      dispatch(clearCustomerVehicleData());
      dispatch(resetToast()); // ✅ Reset toast before fetching new data
      dispatch(setToastShownAction(false)); // ✅ Ensure toast is marked as not shown at the beginning
  
      const result = await dispatch(loadCustomerVehicleReferencesData(selectedCustomer.externalCustomerReference, unitType));
  
      setIsRefetching(false); // ✅ Ensure the spinner stops after API call
  
      if (result?.success) {
        dispatch(setToastShownAction(true)); // ✅ Mark toast as shown AFTER success
      } else {
        dispatch(setToastShownAction(true)); // ✅ Mark toast as shown AFTER failure
      }
    }
  };
  
  useEffect(() => {
    if (!isVehiclesInfoListLoading && toastShown) { // ✅ Ensure toast is shown only after loading completes
      if (!hasError && customerVehicleInfoList.length > 0) {
        addToast({
          title: t("TS_temp:VehicleDataLoaded"),
          text: t("TS_temp:VehicleDataLoadedSuccessfully"),
          type: "success",
        });
      } else if (hasError) {
        addToast({
          title: t("TS_temp:VehicleDataLoadedFail"),
          text: t("TS_temp:VehicleDataLoadedFailedMessage"),
          type: "error",
        });
      }
  
      dispatch(setToastShownAction(false)); // ✅ Prevent duplicate toasts
    }
  }, [isVehiclesInfoListLoading, customerVehicleInfoList, hasError, toastShown]);
  
 // ✅ Reset `isRefetching` only when API finishes (data is loaded or failed)
 useEffect(() => {
  if (!isVehiclesInfoListLoading && (customerVehicleInfoList.length > 0 || hasError)) {
    setIsRefetching(false);
  }
}, [isVehiclesInfoListLoading, customerVehicleInfoList, hasError]);

  useEffect(() => {
    setUnitType(settings?.unit)
  }, [settings]);

  useEffect(() => {
    if (customerVehicleInfoList && customerVehicleInfoList.length > 0) {
      setSearchResults(customerVehicleInfoList);
    }
  }, [customerVehicleInfoList]);

  useEffect(() => {
    if (searchCustomerReference !== selectedCustomer.externalCustomerReference) {
      clearCustomerStoreData();
      //Due to univesal search issue adding below condition
      if (selectedCustomer && selectedCustomer.externalCustomerReference !== '00000000-0000-0000-0000-000000000000') {
        refetchData();
        dispatch(setToastShownAction(true));
      }
    }
  }, [selectedCustomer])


  const handleCustomerSort = (columnId: string, sortDirection: string) => {
    if (customerVehicleInfoList && customerVehicleInfoList.length > 0) {
      if (columnId === 'convertedOdometer') {
        columnId = 'odometer'
      }
      let sortedResults = _.sortBy(customerVehicleInfoList, columnId);
      if (sortDirection === 'desc') {
        sortedResults = _.reverse(sortedResults);
      }
      dispatch(sortCustomerVehicleDataList(sortedResults as Array<simplifiedCustomerVehicleInfo>));
    }
  }

  const clearCustomerStoreData = () => {
    dispatch(clearCustomerVehicleData());
    setSearchResults([])
  }

  const handleCustomerSearch = (searchString: string) => {
    if (searchString.length > 0) {
      if (customerVehicleInfoList && customerVehicleInfoList.length > 0) {
        const splitedString = searchString.split(",");
        let filteredDataArray;
        if (splitedString && splitedString.length > 0) {
          filteredDataArray = splitedString.map((searchstr: string) => {
            searchstr = searchstr.trim();
            if (searchstr === "") return [];
            return customerVehicleInfoList.filter(a => _.values(a).some((b: any) => {
              if (typeof b === 'string') {
                return b?.toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else if (typeof b === 'object') {
                return JSON.stringify(b).toLocaleLowerCase().includes(searchstr.toLocaleLowerCase())
              } else {
                return b?.toString().toLocaleLowerCase() === searchstr.toLocaleLowerCase()
              }
            }
            ));
          })
        }

        setSearchResults(_.flatten(filteredDataArray) as Array<simplifiedCustomerVehicleInfo>);
        return false;
      }
    } else {
      setSearchResults(customerVehicleInfoList);
    }
  }

  return <>
    {selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead &&
      <div className="customer-vehicles-container sdds-container-fluid">
        <div className='sdds-headline-04' data-cy="CustomerVehiclesPageNumberOfCustomerVehicles-cy">{t("TS_core:NumberOfEquipments", [numberOfVehiclesCount.toString()])}</div>
        <div className='dt-row sdds-u-mt2'></div>
        <div className="dt-row sdds-row">
          <div className='dt-col sdds-no-padding sdds-col-md-12 sdds-col-sm-12 sdds-col-xs-12 '>
            <div className='dt-container'>
              <CustomerVehiclesDataTable customerVehiclesData={searchResults} onSearch={handleCustomerSearch} onSortData={handleCustomerSort} onRefetchData={refetchData} isRefetching={isRefetching} />
            </div>
          </div>
        </div>
      </div>
    }
    <Toast toastQueue={toastQueue} />
  </>
}

export default CustomerVehicles;
