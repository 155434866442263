import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumnsConfig } from "../../../helpers/CustomerVehiclesInfoServices";
import { DataTable, DataRow, DataCell, Spinner, Pagination } from "@cd/sdds-common-components-react";
import { useAppSelector } from "../../../redux/store";
import PositionData from '../Position'
import { utcFormatFromStringWithoutTime } from "../../../helpers/timeFormatter";
import { microServiceRedirector } from "../../../helpers/microserviceRedirect";
import CustomerVehicleExcelExportButton from "../CustomerVehicleExcelExportButton";
import ExportIcon from "../../../icons/ExportIcon";

interface CustomerVehiclesDataTableTypes {
  customerVehiclesData: Array<any>
  onSortData?: (columnId: string, sortDirection: string) => void
  onSearch?: (searchString: string) => void
  onRefetchData?: () => void; // ✅ Add refetch function
  isRefetching?: boolean
}

const CustomerVehiclesDataTable = ({ customerVehiclesData, onSearch, onSortData, onRefetchData, isRefetching  }: CustomerVehiclesDataTableTypes) => {
  const { t } = useTranslation()
  const columnConfig = getColumnsConfig(t)
  const { isVehiclesInfoListLoading, hasError, percentage } = useAppSelector<CustomerVehiclesStateType>(state => state.customerVehiclesInfoStore);
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 20;


  useEffect(() => {
    setCurrentPage(1)
  }, [customerVehiclesData])


  const handlePaginationData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return customerVehiclesData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, customerVehiclesData]);

  return <>
    <DataTable
      title={`${t("TS_core:CustomerVehicles")}`}
      columns={columnConfig.columns}
      onSearchChange={onSearch}
      onSort={onSortData}
      actionBar={<CustomerVehicleExcelExportButton slot="sdds-table__actionbar" data={customerVehiclesData} disabled={!customerVehiclesData || customerVehiclesData.length === 0 || percentage !== 100}>
        <span>{t("TS_core:ExportToExcel")}</span><span className='sdds-btn-icon'><ExportIcon /></span>
      </CustomerVehicleExcelExportButton>} >

      <>
        {(isVehiclesInfoListLoading || isRefetching) && !hasError && <div className='loader'><div className="spinner">{t("TS_core:LoadingCustomerVehicles")}<Spinner size='lg' value={percentage} /></div></div>}
        {/* ✅ If API fails, show Reload button */}
        {hasError && <>
            <div className='error-message'>
              <p className="error-msg">
                {t("TS_temp:VehicleDataReFetchMessage")}
              </p>
              <button type="button" className="sdds-btn sdds-btn-sm sdds-red-600"  onClick={onRefetchData} >Refetch Data</button>
            </div>
          </>}
        {!isVehiclesInfoListLoading && !hasError && customerVehiclesData && handlePaginationData.map((row, index) => {
          return <DataRow key={`row-${index}`}>
            {columnConfig.columns.map((col: ColumnConfigType, colIndex: number) => {
              switch (col.columnid) {
                case 'identification':
                  return <DataCell key={`col-index-${colIndex}`}>
                    <>
                      <span>
                        <a style={{ color: "#2058A8", textDecoration: "none" }} target="_blank" href={`${microServiceRedirector(process.env.REACT_APP_AZURE_REDIRECT_URI, 'customer', 'equipment')}communication/${row.externalEquipmentReference}/communication-history`} rel="noopener noreferrer" >
                          {row.identification},
                          <br />
                          {row.chassisNumber}
                        </a>
                      </span>
                    </>
                  </DataCell>
                case 'packageName':
                  return <DataCell key={`col-index-${colIndex}`}>
                    <ul>
                      {row.package && row.package.map((item: packageInfo) => {
                        return <li key={`sub-${index}`}>
                          <span title={`${t("From")} ${utcFormatFromStringWithoutTime(item?.timeStart)} ${(item?.timeStop) ? `${t("To")} ${utcFormatFromStringWithoutTime(item?.timeStop)}` : ''}`}>
                            {item?.packageName ? item.packageName : ''}
                          </span>
                        </li>
                      })}
                    </ul>
                  </DataCell>
                case 'position':
                  return <DataCell key={`col-index-${colIndex}`} data-cy="CustomerVehiclesComponentPostionDataColumn-cy">
                    {row?.latitude && row?.longitude && <>
                      <PositionData latitude={row?.latitude} longitude={row?.longitude} />
                    </>
                    }
                  </DataCell>
                default:
                  return <DataCell key={`col-index-${colIndex}`}>
                    {row[col.columnid]}
                  </DataCell>
              };
            })}
          </DataRow>;
        })
        }
      </>
    </DataTable>
    <>
      {handlePaginationData.length > 0 && (percentage === 100) && <Pagination currentPage={currentPage} totalCount={customerVehiclesData.length} pageSize={pageSize} onPageChange={(page: any) => setCurrentPage(page)} />}
    </>
  </>

}
export default CustomerVehiclesDataTable;