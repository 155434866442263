/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import { UniversalSearchActions,  CustomerOverview, loadCustomerData, NoRoleDisclaimer, useToast, Toast } from '@cd/sdds-common-components-react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Customer.scss';
import { RBACWrapper } from 'react-simple-rbac';
import { TooltipWithLockSvg } from '@cd/sdds-common-components-react/lib/esm/components/assets';
import { UserContext } from '../../appContext';


const Customer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { customerInfo }: any = useAppSelector<RootState>(state => state)
  const { selectedCustomer, customerApiLoading,  hasError, errorMessage } = useAppSelector<SearchState>(state => state.universalSearch)
  const [eqSearchStatus, setEqSearchStatus] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState('');
  const pagelinks = ['', 'customer-vehicles','monitoring-report'];
  let { idParam } = useParams<any>();
  const [scaniaSignedAndRead, setScaniaSignedAndRead] = useState<boolean>(false);
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);
  const { toastQueue, addToast } = useToast()

  useEffect(() => {
    if (hasError && errorMessage !== '') {
      addToast({
        title: t("TS_core:UnExpectedErrorTitle"), text: t("TS_core:UnExpectedErrorMessage"),
        type: "error"
      })
    }
  }, [hasError, errorMessage]);

  useEffect(() => {
    if (idParam) {
      dispatch(UniversalSearchActions.fetchCustomerInfo(idParam))
    }
  }, [idParam])

  useEffect(() => {
    //   /* Preserve vehicle selection by setting external equipment ref value in state
    //      1. when vehicle selection is done in current module but user navigates away to a sub section that doesnt need vehicle selection (loses it from url) and then navigates back -selectedVehicle
    //      2. when vehicle selection was done in another module and is part of url when landing on this module -idParam
    //    */
    const pageParam = pathname.split("/").pop();
    if(pageParam && pagelinks.indexOf(pageParam.toLowerCase()) > -1){
      setActiveItem(pageParam);
    }else{
      navigate(pagelinks[0]);
    }

  }, [pathname])

  useEffect(() => {
    if (selectedCustomer) {
      dispatch(loadCustomerData(selectedCustomer));
    }
  }, [selectedCustomer])

  useEffect(() => {
    if(selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead){
      setScaniaSignedAndRead(false)
    }else{
      setScaniaSignedAndRead(true)
    }
  }, [selectedCustomer, selectedCustomer?.scaniaLegalDocumentationSigned , selectedCustomer?.scaniaRead]);

  const handleClick = (item: any) => {
    if(selectedCustomer?.externalCustomerReference){
       idParam = selectedCustomer.externalCustomerReference
     }
    if (!idParam) return;
    navigate(`/customer/${idParam}/${item}`);
  }

  const checkActive = (item: any, className: any) => {
    return activeItem === item ? className : "";
  }
    return <>
    {
        (!loggedInProfileInfo.role || loggedInProfileInfo.role === null) ? (
          <>
          <NoRoleDisclaimer />
          </>
        ) : (
          <div className='landing-page'>
          <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","Assistance"]}>
          <CustomerOverview
              searchStatus={eqSearchStatus} setSearchStatus={setEqSearchStatus}
              equipmentRedirectPath="customer"
              isCustomerApisLoading={customerApiLoading}
              customerInfo={customerInfo}
              selectedCustomer={selectedCustomer} />
          </RBACWrapper>
          <div className="st-view-wrapper">
          {selectedCustomer &&
              <>
                <sdds-inline-tabs-fullbleed>
                  <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","Assistance"]}>
                  <div role="button" className={`sdds-inline-tabs-fullbleed--tab ${checkActive('customer-vehicles', "sdds-navigation-tabs--tab__active")}`} onClick={() => handleClick('customer-vehicles')}  id="customer-vehicles_tour_id">
                  {scaniaSignedAndRead &&<span className='tooltipLockIcon'><TooltipWithLockSvg tooltipText={t("TS_core:ScaniaReadForbidden")} uniqueId={'CustomerVehiclesInfoIcon'}/></span> }
                    {t("TS_core:CustomerVehicles")}</div>
                </RBACWrapper>
                <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","Assistance"]}>
                  <div role="button" className={`sdds-inline-tabs-fullbleed--tab ${checkActive('monitoring-report', "sdds-navigation-tabs--tab__active")}`} onClick={() => handleClick('monitoring-report')} id="MonitoringReportRecipients_tour_id">
                  {scaniaSignedAndRead &&<span className='tooltipLockIcon'><TooltipWithLockSvg tooltipText={t("TS_core:ScaniaReadForbidden")} uniqueId={'MonitoringReportRecipientsWebInfoIcon'}/></span> }
                    {t("TS_core:MonitoringReportRecipientsWeb")}</div>
                </RBACWrapper>
                </sdds-inline-tabs-fullbleed>

                <div className="panels">
                  <div className="panel active">
                    <Outlet />
                  </div>
                </div>
              </>
            }
          </div> 
        </div>
        )
      }
      <Toast toastQueue={toastQueue} />
    </>
}

export default Customer;