import React, { useState, useEffect } from 'react';
import { useIsAuthenticated, AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import './App.css';
import { callCsttFacadeApis } from './adapters/UserAdapter';
import { loginRequest } from './authConfig';
import { Spinner, Header, UserSettings, MOpinionFeedback, Toast, useToast } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import { RBACProvider, RBACWrapper } from 'react-simple-rbac';
import { UserContext } from './appContext';
import Customer from './pages/Customer';
import CustomerVehicles from './pages/Customer/CustomerVehicles';
import MonitoringReport from './pages/Customer/MonitoringReport';
import CustomerTour from './components/CustomerTour';
import { useSelector } from 'react-redux';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [loggedInProfileInfo, setLoggedInProfileInfo] = useState<ProfileInformationType>({
    "firstName": "",
    "lastName": "",
    "email": "",
    "role": "",
    "permissions": {
      "developer": false
    },
    "settings": {
      "csddashboard": "",
      "unit": "",
      "language": "en-GB"
    },
    "scaniaId": "",
    "impersonationAccess": false
  });

  const { i18n } = useTranslation();
  const [apiResponse, setApiResponse] = useState({});
  const [apiCalled, setApiCalled] = useState<string>('getProfileInfo');

  const [profileInfoLoader, setProfileInfoLoader] = useState<AsyncStateType>({ loading: true, error: false });

  const { toastQueue, addToast } = useToast()
  const { toastStore }: any = useSelector(state => state)


  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchStatus, setSearchStatus] = useState<boolean>(false);
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [searchResult, setSearchResult] = useState<string>('');
  //For header to maintain state of which app user is currently on
  const currentApp = 'Customer';

  useEffect(() => {
    if (isAuthenticated) {
      setApiCalled('getProfileInfo');
      callCsttFacadeApis(apiCalled, setApiResponse, setProfileInfoLoader, []);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (profileInfoLoader.error) {
      window.localStorage.clear();
      window.sessionStorage.clear();
      window.location.reload();
    }
  }, [profileInfoLoader]);

  useEffect(() => {
    setLoggedInProfileInfo(apiResponse as ProfileInformationType)
  }, [apiResponse]);

  useEffect(() => {
    i18n.changeLanguage(loggedInProfileInfo?.settings?.language || 'en-GB');
  }, [loggedInProfileInfo, i18n]);

  useEffect(() => {
    if(toastStore.toastMessage !== '') {
      addToast({
        title: toastStore.toastMessage.title, text: toastStore.toastMessage.text,
        type: toastStore.toastMessage.type
      });
    }    
  }, [toastStore.toastMessage]);


  return (
    <>
      <div className="App">
        <sdds-theme global="true" />
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
        >
        </MsalAuthenticationTemplate>
        <AuthenticatedTemplate>
          {profileInfoLoader.loading && (<Spinner size='lg' />)}
          {(!(profileInfoLoader.error || profileInfoLoader.loading)) && (
            <RBACProvider roles={[loggedInProfileInfo.role]} >
              <UserContext.Provider value={loggedInProfileInfo}>
                <Router >
                  <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic", "Assistance"]}>
                    <Header
                      profileInfo={loggedInProfileInfo}
                      currentApp={currentApp}
                      setSearchComplete={setSearchStatus}
                      setSearchStatus={setSearchResult}
                      equipmentRedirectPath={'customer'}
                      tours={[<CustomerTour loggedInProfileInfo={loggedInProfileInfo} />]}
                    >
                    </Header>
                  </RBACWrapper>
                  <Routes>
                    <Route path='/customer/:idParam/' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic", "Assistance"]}><Customer /></RBACWrapper></>} >
                      <Route path='customer-vehicles' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic", "Assistance"]}><CustomerVehicles /></RBACWrapper></>} />
                      <Route path='monitoring-report' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic", "Assistance"]}><MonitoringReport /></RBACWrapper></>} />
                    </Route>
                    <Route path='/customer' element={<Customer />} />
                    <Route index element={<Customer />} />

                    <Route path="/user-settings" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic", "Assistance"]}><UserSettings
                      userDetails={loggedInProfileInfo}
                      setLoggedInProfileInfo={setLoggedInProfileInfo} />
                    </RBACWrapper></>} />
                  </Routes>
                </Router>
              </UserContext.Provider>
            </RBACProvider>)}
          {profileInfoLoader.error && (<p>{'Profile Info Api : ' + profileInfoLoader.errorMessage}</p>)}
        </AuthenticatedTemplate>
        {<Toast toastQueue={toastQueue} />} 
        <MOpinionFeedback />
      </div>
    </>
  );
};

export default App;
