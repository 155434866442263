import { createSlice } from '@reduxjs/toolkit'

// Slice
const slice = createSlice({
    name: 'toastStore',
    initialState: {
        toastMessage: ''
    },
    reducers: {
        setToast: (state, action) => {
            state.toastMessage = action.payload;  
        }
    },
});

export default slice.reducer

// Actions
const { setToast } = slice.actions

export const showToast = (message: ToastMessageType ) => async (dispatch: any) => {
    dispatch(setToast(message));
}
